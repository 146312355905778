import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DocTypesState } from './state';

export const DocTypesFeature = 'DocTypesFeature';

export const docTypesFeatureStateSelector =
  createFeatureSelector<DocTypesState>(DocTypesFeature);

export const selectDocTypes = createSelector(
  docTypesFeatureStateSelector,
  (state: DocTypesState) => state.docTypes,
);

export const selectDocTypesLoading = createSelector(
  docTypesFeatureStateSelector,
  (state: DocTypesState) => state.loadingDocTypes,
);

export const selectDocTypeCreating = createSelector(
  docTypesFeatureStateSelector,
  (state: DocTypesState) => state.creatingDocType,
);

export const selectCloseCreatingDocTypeModal = createSelector(
  docTypesFeatureStateSelector,
  (state: DocTypesState) => state.closeCreatingDocTypeModal,
);

export const selectDocTypeLoading = createSelector(
  docTypesFeatureStateSelector,
  (state: DocTypesState) => state.loadingDocType,
);

export const selectDocType = createSelector(
  docTypesFeatureStateSelector,
  (state: DocTypesState) => state.docType,
);

export const selectDocTypeEditing = createSelector(
  docTypesFeatureStateSelector,
  (state: DocTypesState) => state.editingDocType,
);

export const selectCloseEditingDocTypeModal = createSelector(
  docTypesFeatureStateSelector,
  (state: DocTypesState) => state.closeEditingDocTypeModal,
);

export const fromDocTypes = {
  selectDocTypes,
  selectDocTypesLoading,
  selectCloseCreatingDocTypeModal,
  selectDocTypeCreating,
  selectDocType,
  selectDocTypeLoading,
  selectDocTypeEditing,
  selectCloseEditingDocTypeModal,
};
