import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { tuiIsPresent } from '@taiga-ui/cdk';
import { TuiSelectOptionComponent } from '@taiga-ui/kit/components/select-option';
import * as i1 from '@taiga-ui/core';
import { TuiPrimitiveCheckboxModule } from '@taiga-ui/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
class TuiMultiSelectOptionComponent extends TuiSelectOptionComponent {
  get size() {
    var _a;
    return this.option.size === 'l' || ((_a = this.dataList) === null || _a === void 0 ? void 0 : _a.size) === 'l' && !this.option.size ? 'l' : 'm';
  }
  get selected() {
    const {
      value
    } = this.option;
    return tuiIsPresent(value) && tuiIsPresent(this.value) && this.value.some(item => this.matcher(item, value));
  }
}
TuiMultiSelectOptionComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiMultiSelectOptionComponent_BaseFactory;
  return function TuiMultiSelectOptionComponent_Factory(t) {
    return (ɵTuiMultiSelectOptionComponent_BaseFactory || (ɵTuiMultiSelectOptionComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiMultiSelectOptionComponent)))(t || TuiMultiSelectOptionComponent);
  };
})();
TuiMultiSelectOptionComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiMultiSelectOptionComponent,
  selectors: [["tui-multi-select-option"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 5,
  consts: [[3, "size", "value"], [3, "ngTemplateOutlet"]],
  template: function TuiMultiSelectOptionComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "tui-primitive-checkbox", 0);
      i0.ɵɵpipe(1, "async");
      i0.ɵɵelementContainer(2, 1);
    }
    if (rf & 2) {
      i0.ɵɵproperty("size", ctx.size)("value", i0.ɵɵpipeBind1(1, 3, ctx.selected$));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngTemplateOutlet", ctx.context.$implicit);
    }
  },
  dependencies: [i1.TuiPrimitiveCheckboxComponent, i2.NgTemplateOutlet, i2.AsyncPipe],
  styles: ["@keyframes _ngcontent-%COMP%_retrigger{0%{left:1px}to{left:2px}}[_nghost-%COMP%]{display:flex;flex:1;flex-direction:inherit;grid-gap:.75rem;gap:.75rem;align-items:center;animation:_ngcontent-%COMP%_retrigger 1s}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMultiSelectOptionComponent, [{
    type: Component,
    args: [{
      selector: 'tui-multi-select-option',
      templateUrl: './multi-select-option.template.html',
      styleUrls: ['./multi-select-option.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class TuiMultiSelectOptionModule {}
TuiMultiSelectOptionModule.ɵfac = function TuiMultiSelectOptionModule_Factory(t) {
  return new (t || TuiMultiSelectOptionModule)();
};
TuiMultiSelectOptionModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiMultiSelectOptionModule
});
TuiMultiSelectOptionModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiPrimitiveCheckboxModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMultiSelectOptionModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiPrimitiveCheckboxModule],
      declarations: [TuiMultiSelectOptionComponent],
      exports: [TuiMultiSelectOptionComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiMultiSelectOptionComponent, TuiMultiSelectOptionModule };
