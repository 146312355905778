import { OnInit, Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, withLatestFrom } from 'rxjs';
import { selectUser } from 'src/app/core/store/selectors';
import { isPresent } from 'src/app/core/utils/isPresent';
import { LanguageDto } from '../services/languages-api.service';
import { selectContentLanguages } from '../store/selectors';

@UntilDestroy()
@Pipe({
  name: 'language',
  pure: false,
})
export class LanguagePipe implements PipeTransform, OnInit {
  private languages!: LanguageDto[] | null;

  constructor(private readonly store$: Store) {}

  getParties$ = this.store$
    .select(selectContentLanguages)
    .pipe(
      untilDestroyed(this),
      filter(isPresent),
      withLatestFrom(this.store$.select(selectUser).pipe(filter(isPresent))),
    )
    .subscribe(([languages, user]) => {
      this.languages = languages;
    });

  ngOnInit(): void {}

  transform(id: number): string {
    if (!id || !this.languages) {
      return '';
    }

    return getItemsHtml(id, this.languages);
  }
}

function getItemsHtml(id: number, allLanguages: LanguageDto[]): string {
  const langName = allLanguages.find((lang) => lang.id === id)?.displayName;

  return `<span class="lang-name-in-column">${langName}</span>`;
}
