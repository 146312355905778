import { Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  shareReplay,
} from 'rxjs';
import { isPresent } from 'src/app/core/utils/isPresent';
import { fromDocTypes } from 'src/app/modules/doc-types/store/selectors';

@UntilDestroy()
@Pipe({
  name: 'docTypes',
})
export class DocTypesPipe implements PipeTransform {
  allDocTypes$: Observable<any[]>;

  constructor(private store: Store) {
    this.allDocTypes$ = this.store.select(fromDocTypes.selectDocTypes).pipe(
      untilDestroyed(this),
      filter(isPresent),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
    );
  }

  transform(docTypeIds: number[]): Observable<string> {
    return combineLatest([this.allDocTypes$, of(docTypeIds)]).pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      filter(isPresent),
      untilDestroyed(this),
      map(([allDocTypes, docTypeIds]) => {
        let resultDocTypes = docTypeIds.map((id: number) => {
          const docType = allDocTypes.find((dt) => dt.id === id);
          return docType ? docType.name : '';
        });

        return resultDocTypes ? resultDocTypes.join(', ') : '';
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
