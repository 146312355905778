import { Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  shareReplay,
} from 'rxjs';
import { isPresent } from 'src/app/core/utils/isPresent';
import { fromDocTypes } from 'src/app/modules/doc-types/store/selectors';

@UntilDestroy()
@Pipe({
  name: 'docType',
})
export class DocTypePipe implements PipeTransform {
  docTypes$: Observable<any[]>;

  constructor(private store: Store) {
    this.docTypes$ = this.store.select(fromDocTypes.selectDocTypes).pipe(
      untilDestroyed(this),
      filter(isPresent),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
    );
  }

  transform(docTypeId: number): Observable<string> {
    return combineLatest([this.docTypes$, of(docTypeId)]).pipe(
      filter(isPresent),
      untilDestroyed(this),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      map(([docTypes, id]) => {
        const docType = docTypes.find((dt) => dt.id === id);
        return docType ? docType.name : '';
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
